import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom';

import Login from './pages/Login';
import Home from './pages/Home';
import Chat from './pages/Chat';
import Signup from './pages/Signup';
import { auth } from './services/firebase';

import './App.css';

type PrivateRouteProps = {
  component: any;
  authenticated: boolean;
};

function PrivateRoute({
  component: Component,
  authenticated,
  ...rest
}: PrivateRouteProps & RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

type PublicRouteProps = { component: any; authenticated: boolean };

function PublicRoute({
  component: Component,
  authenticated,
  ...rest
}: PublicRouteProps & RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/chat" />
        )
      }
    />
  );
}

function App() {
  const [loading, setLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);

  React.useEffect(() => {
    auth().onAuthStateChanged((user) => {
      setAuthenticated(user !== null);

      setLoading(false);
    });
  }, []);

  return loading === true ? (
    <h2>Loading...</h2>
  ) : (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <PrivateRoute
          path="/chat"
          authenticated={authenticated}
          component={Chat}
        ></PrivateRoute>
        <PublicRoute
          path="/signup"
          authenticated={authenticated}
          component={Signup}
        ></PublicRoute>

        <PublicRoute
          path="/login"
          authenticated={authenticated}
          component={Login}
        ></PublicRoute>
      </Switch>
    </Router>
  );
}

export default App;
