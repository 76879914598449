import React, { useEffect } from 'react';

import { auth, db } from '../services/firebase';

type ChatObject = {
  timestamp: number;
  content: string;
  uid: string;
};

function Chat() {
  const user = auth().currentUser;
  const [chats, setChats] = React.useState<ChatObject[]>([]);
  const [content, setContent] = React.useState('');
  const [readError, setReadError] = React.useState(null);
  const [writeError, setWriteError] = React.useState(null);

  useEffect(() => {
    setReadError(null);
    return db
      .collection('chats')
      .orderBy('timestamp')
      .onSnapshot({
        next: (querySnapshot) => {
          const updatedChats = querySnapshot.docs.map(
            (docSnapshot) => docSnapshot.data() as ChatObject
          );
          setChats(updatedChats);
        },
      });
  }, []);

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const messageToSend = content;
    setWriteError(null);
    setContent('');
    try {
      await db.collection('chats').add({
        content: messageToSend,
        timestamp: Date.now(),
        uid: user!.uid,
      });
    } catch (error) {
      setContent(messageToSend);
      setWriteError(error.message);
    }
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setContent(event.target.value);
  }

  return (
    <div>
      <div className="chats">
        {readError ? (
          <p>{readError}</p>
        ) : (
          chats.map((chat) => {
            return <p key={chat.timestamp}>{chat.content}</p>;
          })
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <input onChange={handleChange} value={content}></input>
        {writeError ? <p>{writeError}</p> : null}
        <button type="submit">Send</button>
      </form>
      <div>
        Logged in as: <strong>{user!.email}</strong>
      </div>
    </div>
  );
}

export default Chat;
