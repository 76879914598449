import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDSxreG7knI_VP51cCpus5NzRibXBByjWg',
  authDomain: 'secret-code-tracker.firebaseapp.com',
  databaseURL: 'https://secret-code-tracker.firebaseio.com',
  projectId: 'secret-code-tracker',
  storageBucket: 'secret-code-tracker.appspot.com',
  messagingSenderId: '153143295934',
  appId: '1:153143295934:web:3caae5b1a7a76462e603ef',
  measurementId: 'G-J21CXKW7Z5',
};
// Initialize Firebase
firebase.initializeApp(config);
firebase.analytics();

export const auth = firebase.auth;
export const db = firebase.firestore();
